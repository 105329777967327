import Ajv from "ajv";
import addFormats from "ajv-formats"
import authService from "./auth.service";

import {
  ApolloClient,
  ApolloLink,
  DocumentNode,
  HttpLink,
  InMemoryCache,
  OperationVariables,
  gql
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { GraphQueryResponse } from "services/types";
import store from "store/store";

const ajv = new Ajv({
  strict: false
});
addFormats(ajv);

type NestedSerializer = string | NestedSerializer[] | { [key: string]: NestedSerializer };
type GraphQLResultsSerializer = Record<string, NestedSerializer>;

type JSONSchemaDefinition = {
  type: string;
  format?: string;
  optional?: boolean;
  graph?: string;
}

type GraphQLQueryConfig = {
  name: string;
  operation: string;
  mutation: boolean; // default false
  auth: boolean | "optional"; // default true
  input: Record<string, JSONSchemaDefinition>;
  output: Array<Record<string, any>> | Record<string, any>;
  serializer: GraphQLResultsSerializer | null;
}

type GraphQLQueryConfigPublic = {
  name?: string; // set operation name different to query name
  mutation?: boolean; // default false
  auth?: boolean | "optional"; // default true
  input?: Record<string, JSONSchemaDefinition>;
  output?: Array<Record<string, any>> | Record<string, any>;
  serializer?: GraphQLResultsSerializer;
}

type GraphQLExecutionConfig = {
  error?: boolean;
}

/* 
used to define configs for queries/mutations in files:
  <module>/graphql/Queries.graphql.ts
  <module>/graphql/Mutations.graphql.ts
*/
export class GraphQueryDefinition {
  config: GraphQLQueryConfigPublic;
  constructor(config: GraphQLQueryConfigPublic) {
    this.config = config;
  }
}


class GraphService {
  client: ApolloClient<any>;
  queries: Array<any>;
  initialized: boolean;
  constructor(client: ApolloClient<any>) {
    this.client = client;
    this.queries = [];
    this.initialized = false;
  }
  init = async (modules: Array<string>) => {
    if (this.initialized) return;
    this.initialized = true;
    /*modules.forEach(async (dir) => {
      const queries_path = `modules/${dir}/graphql`;
      const queries = await this.importModule(dir, `${queries_path}`, `Queries`);
      this.loadImports(queries); 
      const mutations = await this.importModule(dir, `${queries_path}`, `Mutations`);
      this.loadImports(mutations);
      //console.log(`LOADED QUERIES [${dir}]`, this.queries)
    })*/
    for (let i=0; i<modules.length; i++) {
      const dir = modules[i];
      const queries_path = `modules/${dir}/graphql`;
      const queries = await this.importModule(dir, `${queries_path}`, `Queries`);
      this.loadImports(queries); 
      const mutations = await this.importModule(dir, `${queries_path}`, `Mutations`);
      this.loadImports(mutations);
      //console.log(`LOADED QUERIES [${dir}]`, this.queries);
      /*if (i+1 === modules.length) {
        console.log("Last module finished loading");
        
      }*/
    }
    await authService.init();
  }
  importModule = async (module: string, path: string, type: "Queries" | "Mutations") => {
    try {
      return await import(`../${path}/${type}.graphql.ts`);
    } catch(e) {
      const msg = `Module [${module}] requires file ../${path}/${type}.graphql.ts`;
      //console.log(msg, e);
      throw new Error(msg);
    }
  }
  loadImports = (queries: Record<string, GraphQueryDefinition>) => {
    Object.keys(queries).forEach((fn_name: string) => {
      if (queries[fn_name] instanceof GraphQueryDefinition) {
        this.registerQuery(fn_name, queries[fn_name].config);
      }
    });
  }
  registerQuery = (query_name: string, query: GraphQLQueryConfigPublic) => {
    if (this.queries.filter(item => item.name === query_name).length > 0) {
      throw new Error(`Query/Mutation already registered with this name: ${query_name}`);
    }
    const query_config = {
      name: query_name,
      operation: (query.name ? query.name : query_name),
      auth: ("auth" in query ? query.auth : true),
      input: (query.input ? query.input : {}),
      output: query.output || ['clientMutationId'],
      mutation: ('mutation' in query ? query.mutation : false),
      serializer: ('serializer' in query && query.output ? query.serializer : null),
    }
    this.queries.push(query_config);
  }
  gql = (config: GraphQLQueryConfig) => {
    //console.log("GQL 1", config)
    const input = ('input' in config ? [`( ${
      Object.keys(config.input).map((property_name: string) => {
        const schema = (config.input[property_name] as JSONSchemaDefinition);
        //console.log(schema);
        let required = true;
        let text;
        switch (schema.type) {
          case 'boolean':
            text = 'Boolean';
            break;
          case 'string':
            text = 'String';
            if (schema.format) {
              switch (schema.format) {
                case 'uuid':
                  text = 'UUID';
                  break;
                case 'email':
                  text = 'Email';
                  break;
                default:
                  throw new Error("Format not accepted yet");
              }
            }
            break;
          case 'integer':
            text = 'Int';
            break;
          case 'number':
            text = 'Float';
            break;
          default:
            throw new Error("Cannot use object or array, must use 'string' and graph: 'JSON'");
        }
        if (schema.graph) {
          text = schema.graph;
        }
        if ("optional" in schema && schema.optional === true) required = false;
        return `$${property_name}: ${text}${(required === true ? '!' : '')}`;
      }).join(`, `)
    } )`, `(input: { ${
      Object.keys(config.input || []).map((property_name: string) => {
        return `${property_name}: $${property_name}`
      }).join(`, `)
    } } )`] : null);
    //console.log("GQL 2", input)
    const gql_string = `${(config.mutation === true ? (
      `mutation ${config.operation}${(input !== null ? input[0] : ``)} {
  ${config.name}${(input !== null ? input[1] : ``)} {
    ${this.gqlResponse(config.output)}
  }
}`
    ) : ``)}`
    //console.log(`Generated GQL: \n${gql_string}`);
    return gql`${gql_string}`;
  }
  gqlResponse = (output: Array<string> | Record<string, any> | null, text = ``) => {
    if (Array.isArray(output)) {
      if (output.length === 0) throw new Error("Array requires inner values");
      text += `
        ${(output.join(`
        `))}
   `;
    } else if(output === null) {
      text += `
        `;
    } else {
      if (Object.keys(output).length === 0) throw new Error("Object requires inner values");
      text += `${(
        Object.keys(output).map((key: string) => {
          if (output[key] === null) return `${key}
        `
          return `${key} { ${this.gqlResponse(output[key])} } 
        `;
        }).join(`\n`)
      )}`;
    }
    return text;
  }
  getQueries = (mutation = false): Array<GraphQLQueryConfig> => {
    return this.queries.filter((query: GraphQLQueryConfig) => (
      (query.mutation === mutation)
    ));
  }
  getQueryByName = (name: string, mutation = false): GraphQLQueryConfig => {
    const all_queries = this.getQueries(mutation);
    const matched_queries = all_queries.filter(query => query.name === name);
    if (matched_queries.length === 1) return matched_queries[0];
    //console.log(all_queries, matched_queries, name, mutation)
    throw new Error(`Invalid ${(mutation === true ? 'mutation' : 'query')} name: ${name}`);
  }
  getQueryByOperationName = (name: string): GraphQLQueryConfig => {
    const matched_queries = this.queries.filter(query => query.operation === name);
    if (matched_queries.length === 1) return matched_queries[0];
    throw new Error(`Invalid query name: ${name}`);
  }
  requiredParams = (query: GraphQLQueryConfig) => {
    const required = ([] as Array<string>);
    if (query.input) {
      Object.keys(query.input).forEach((property_name: string) => {
        const schema = query.input[property_name];
        if (!("optional" in (schema as any)) || (schema as any).optional === false) {
          required.push(property_name);
        }
      });
    }
    return required;
  }
  validate = (
    params: Record<string, any>,
    query: GraphQLQueryConfig,
    required: Array<string>
  ) => {
    const properties = query.input;
    const schema = {
      $id: `ad-hoc-schema_${query.mutation === true ? 'mutation' : 'query'}_${query.operation}`,
      type: `object`,
      properties,
      additionalProperties: false,
      required,
    }
    //console.log("schema", schema);
    try {
      const validateFn = ajv.getSchema(schema.$id) || ajv.compile(schema);
      if (validateFn(params)) {
        //console.log("validated", params)
      } else {
        console.error("Query variables validation error", validateFn.errors);
      }
    } catch(e) {
      console.error("validate fn error", e);
      return;
    }
  }
  /*serializeResults = (serializer: GraphQLResultsSerializer, data: any, results: any = null) => {
    const crawl = (data: any, path: string, nested: any) => {
      if (isObject(data)) {
        if (results === null) results = Object();
        Object.keys(data).forEach(key => {
          
        });
      }
    }
    //parsePointer('kfou')
  }*/
  query = async (name: string, params: Record<string, any> | null, config?: GraphQLExecutionConfig) => {
    config
    //console.log("query", name, params, config);
    const query = this.getQueryByName(name, false);
    const required_params = this.requiredParams(query);
    if (required_params.length > 0) {
      if (params === null || Object.keys(params).length === 0) throw new Error(`Requires query params`);
      this.validate(params, query, required_params);
    }
    const gql = this.gql(query);
    return await this.execute(query, name, gql, params);
  }
  mutation = async (name: string, params: Record<string, any> | null, config?: GraphQLExecutionConfig) => {
    config
    //console.log("mutation", name, params, config);
    const query = this.getQueryByName(name, true);
    const required_params = this.requiredParams(query);
    if (required_params.length > 0) {
      if (params === null || Object.keys(params).length === 0) throw new Error(`Requires mutation params`);
      this.validate(params, query, required_params);
    }
    const gql = this.gql(query);
    return await this.execute(query, name, gql, params);
  }
  execute = async (
    query: GraphQLQueryConfig,
    name: string, 
    mutation: DocumentNode, 
    variables: Record<string, any> | null, 
    config?: GraphQLExecutionConfig
  ): Promise<GraphQueryResponse | any> => {
    if (config) console.log(config);
    
    const response = await this.client.mutate({ mutation, variables: (variables as OperationVariables) }).then((response: GraphQueryResponse): GraphQueryResponse => {
      if (response.error) {
        console.error("GraphQL Query Results Error", response);
        return { data: null, error: response.error }
      }
      return { data: response.data[name], error: null }
    }).catch(async (error: any) => {
      if (error.response && error.response.status === 401) {
        await store.getActions().authentication.logout();
        return Promise.reject(error);
      }
      return { data: null, error }
    });
    if (response.error) {
      return { data: null, error: response.error }
    }
    if (query.serializer) {
      /*return {
        data: this.serializeResults(query.serializer, response.data[name]),
        error: null,
      }*/
    }
    return { data: response.data, error: null }
  }
}




const httpLink = new HttpLink({
  uri: process.env.REACT_APP_BASE_URL,
});
const tokenLink = setContext(async (request, { headers }) => {
  const query = graphService.getQueryByOperationName(request.operationName || '');
  if (query.auth === true || (query.auth === "optional" && store.getState().authentication.isAuthenticated)) {
    const jwt_token = await store.getActions().authentication.getAuthToken();
    headers["Authorization"] = `Bearer ${jwt_token}`;
  }
  return headers;
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      ArtistProfile: {
        keyFields: ["userId"], // Use `userId` as a unique identifier if `id` is unavailable
      },
    },
  }),
  link: ApolloLink.from([tokenLink, httpLink]),
});

const graphService = new GraphService(client);

export default graphService;