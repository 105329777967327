import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState }  from 'store/store';
import styled from 'styled-components';
import theme from 'theme/theme';


import { sm } from 'utils/constants';
import TextInput from 'common/components/TextInput/TextInput';
import Typography from '../Typography/Typography';
import Button from '../Button/Button';
import SBModal from 'common/components/Modal/SBModal';
import ModalLayout from '../../../modules/library/pages/components/ModalLayout/ModalLayout';
import { useNavigate } from 'react-router-dom';


type ModalProps = {
  show: boolean;
  onClose?: () => void;
};

const SSORequiredModal = ({ show, onClose }: ModalProps) => {
  
  const navigate = useNavigate()

  const dismissedSSOWarning = useStoreState((state) => state.authentication.dismissedSSOWarning);
  const { setDismissedSSOWarning } = useStoreActions((state) => state.authentication);

  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (!dismissedSSOWarning) {
      setShowModal(show);
    }
  }, [show, dismissedSSOWarning]);


  async function illDoLaterHandler() {
    if (onClose) {
      onClose()
    }
    setDismissedSSOWarning(true)
    setShowModal(false);
    
  }
  async function submitHandler() {
    setShowModal(false);
    setDismissedSSOWarning(true)
    navigate("/account/settings", {state: {setup_sso: true}})
  }

  return (
    <>
      <SBModal
        zIndex={3}
        className={'sso-required'}
        isOpen={showModal}
        width={'390px'}
        height='646px'
        mobileWidth={'100%'}
        withProceedingText={false}
        mobileTop="2%"
        content={
          <ModalLayout titleBottomMargin={'15px'} title={'information'} onClose={() => {
            if(onClose) {
              onClose()
              setDismissedSSOWarning(true)
            } else {
              setShowModal(false);
              setDismissedSSOWarning(true)
            }
            }}>
            <ContentContainer className={'sso-required-modal'}>
              <BoldYellowText>
                Additional Setup Required
              </BoldYellowText>

              <NormalText>
                {'In order to continue to use SongBits you need to attach a Simple Sign On login method to your account.'}
              </NormalText>

              <NormalText>
                {'Currently we support Apple, Google & Facebook.'}
              </NormalText>

              <div style={{display: 'inline'}}>

                <NormalTextInline>
                  {'You will '}
                </NormalTextInline>
                <BoldTexInline>not</BoldTexInline>
                <NormalTextInline>  
                  {' be able to make a purchase until this has been added.'}
                </NormalTextInline>
                
              </div>

              <Row
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '30px',
                }}>
                <FirstButton
                  height={45}
                  borderRadius={50}
                  borderColor={theme.colors.yellow}
                  bgColor={theme.colors.black}
                  labelColor={theme.colors.yellow}
                  className={'sso-required-modal-later-button'}
                  label={
                    <ButtonText
                      text="i'll do this later"
                      fontSize="fz16"
                      fontWeight="bold"
                      fontColor={theme.colors.yellow}
                    />
                  }
                  onClick={illDoLaterHandler}
                />

                <FirstButton
                  className={'sso-required-modal-continue-button'}
                  height={45}
                  borderRadius={50}
                  bgColor={theme.colors.yellow}
                  labelColor={theme.colors.black}
                  label={
                    <ButtonText
                      text="continue"
                      fontSize="fz16"
                      fontWeight="bold"
                      fontColor={theme.colors.black}
                    />
                  }
                  onClick={submitHandler}
                />
              </Row>
            </ContentContainer>
          </ModalLayout>
        }
      />
    </>
  );
};

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  @media (min-width: ${sm}px) {
    height: 600px;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 40px;
    background-color: ${theme.colors.yellow50};
  }
`;

const BoldYellowText = styled.p`
  font-family: HKGrotesk-Black;
  color: ${theme.colors.yellow};
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 20px;
  letter-spacing: -0.03em;
`;

const NormalText = styled.p`
  font-family: HKGrotesk-Regular;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 15px;
  white-space: pre-line;
  letter-spacing: -0.03em;
`;

const NormalTextInline = styled.p`
  font-family: HKGrotesk-Regular;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  white-space: pre-line;
  display: inline;
`;

const BoldTexInline = styled.p`
  font-family: HKGrotesk-Black;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.03em;
  display: inline;
`;


const Row = styled.div`
  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const ButtonText = styled(Typography)`
  font-size: 16px;
  letter-spacing: -0.03em;
  font-family: 'HKGrotesk-Black';
`;

const FirstButton = styled(Button)<{ width?: number }>`
  margin: 0 auto;
  width: 100%;
  border-width: 1px;

  position: relative;
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const Icon = styled.div<{ bottom?: number; left?: number }>`
  position: absolute;
  bottom: 23px;
  left: 8px;
  bottom: ${(props) => (props.bottom ? props.bottom : 23)}px;
  left: ${(props) => (props.left ? props.left : 8)}px;
`;

export const InputWithIcon = styled(TextInput)`
  & > input {
    font-size: 14px;
    letter-spacing: -0.035em;

    padding-left: 32px;
    margin-top: 2px;
  }

  & > div:nth-child(3) {
    padding: 0 0 7px 8px;
  }

  @media (min-width: ${sm}px) {
    & > input {
      font-size: 18px;
    }
  }
`;

export const FCALink = styled.a`
font-family: HKGrotesk-Bold;
color: #fff;
font-size: 16px;
line-height: 20px;
letter-spacing: -0.03em;
text-decoration: none;

display: in

  &:hover {
    text-decoration: none;
  }
`;

export default SSORequiredModal;
