import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { useStoreActions, useStoreState } from 'store/store';

import { useViewport } from 'use-viewport';

import WithRouteAnimation from 'common/hoc/WithRouteAnimation';
import { VIEW_TRACKING_MUTATION, VIEW_TRACKING_VARIABLES } from '../types';
import { VIEW_TRACKING } from '../graphql/Mutations.graphql';

import PageContainer from 'common/layout/PageContainer';
import SignInModal from './components/Modals/SignInModal';
import SBModal from 'common/components/Modal/SBModal';
import ListenNowModal from './components/Modals/ListenNowModal';

import Banner from '../assets/house-drop.jpg';
import PlayImg from '../assets/play.png';
import PauseImg from '../assets/pause.png';
import theme from 'theme/theme';

import { getSoldPercent } from 'utils/calculation';

import {
  Button,
  ButtonsContainer,
  ContentContainer,
  Divider,
  LightText,
  MainContainer,
  PlayButton,
  PlayImage,
  SongBannerContainer,
  SongExtraInfo,
  SongImage,
  SongMainInfo,
  StrapLineTypography,
  SongInfo,
  Cost,
  RowContainer,
  MobileButtonsContainer,
  StrapLineTypographyLarge,
  CountdownContainer,
} from './styled/SongProfilePublic.styled';

import { GET_SONG_BY_SLUG } from '../../auth/graphql/Queries.graphql';
import { SongDataType } from '../../auth/types';

import MOCK_SONG_DATA from '../../auth/MOCK-DATA';
import ErrorPage from 'modules/prereg/pages/ErrorPage';
import { getUserIdFromJWT } from 'utils/functions';

import { sm } from 'utils/constants'; // xs

import SongProfileIconsSection from './components/SongProfileIconsSection/SongProfileIconsSection';
import InfoBenefitsModal from './components/Modals/InfoBenefitsModal';
import BioDataModal from './components/Modals/BioDataModal';
import { checkLive } from 'utils/live-check';
import ExplicitLyrics from 'common/icons/ExplicitLyrics.icon';
import { ExplicitContainer } from './styled/SongProfile.styled';
import Spacer from 'common/components/Spacer/Spacer';
import RegisterInterestModal from './components/Modals/RegisterInterest/RegisterInterestModal';
import { useCountdown } from 'utils/use-countdown';
import Typography from 'common/components/Typography/Typography';
import ReactHowler from 'react-howler';
import restService from 'services/rest.service';


export type PurchasePerksType = {
  type: string;
  title: string;
  orderNumber: number;
  description: string;
  imageLogo?: string;
};

const SongProfilePublic = () => {
  const navigate = useNavigate();
  const userId = getUserIdFromJWT();
  const viewport = useViewport();

  const setShowFCABanner = useStoreActions((state) => state.style.setShowFCABanner);
  const isArtist = useStoreState((state) => state.authentication.auth.tokenData?.is_artist || false);
  const userLocation = useStoreState((state) => state.authentication.location);
  const [songData, setSongData] = useState<SongDataType>();
  const [songPerks, setSongPerks] = useState<PurchasePerksType[]>([]);
  const [showUnauthModal, setShowUnauthModal] = useState<boolean>(false);
  //const [showPersonalisedRiskWarning, setShowPersonalisedRiskWarning] = useState<boolean>(false);
  const [showListenNowModal, setShowListenNowModal] = useState<boolean>(false);
  const [showBioDataModal, setShowBioDataModal] = useState<boolean>(false);
  const [showInfoBenefitsModal, setShowInfoBenefitsModal] = useState<boolean>(false);
  const [showRegisterInterestModal, setShowRegisterInterestModal] = useState<boolean>(false);
  const [isCostView, setIsCostView] = useState<boolean>(true);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [hasRegisteredInterest, setHasRegisteredInterest] = useState<boolean>(true);

  const [createViewTracking] = useMutation<
    VIEW_TRACKING_MUTATION,
    VIEW_TRACKING_VARIABLES
  >(VIEW_TRACKING);

  const { isAuthenticated } = useStoreState((state) => state.authentication);

  const updateHeaderTransparence = useStoreActions(
    (actions) => actions.style.setHeaderTransparent,
  );
  const updateLogoShown = useStoreActions(
    (actions) => actions.style.setLogoShownInHeader,
  );
  const setMainFooterHidden = useStoreActions(
    (state) => state.style.setMainFooterHidden,
  );
  const setReturnUrl = useStoreActions(
    (actions) => actions.location.setPreviousUrl,
  );
  const setBreadcrumbs = useStoreActions(
    (state) => state.location.setBreadcrumbs,
  );
  const setUseTraparentHeaderSwitch = useStoreActions(
    (actions) => actions.style.setUseTraparentHeaderSwitch,
  );
  const showFCABanner = useStoreState((state) => state.style.showFCABanner);

  const isMobile = useCallback(
    () => (viewport.width < sm ? true : false),
    [viewport.width],
  );

  const { user, song } = useParams();

  const { data: songProfile } = useQuery(GET_SONG_BY_SLUG, { // refetch
    variables: { slug: song, userid: userId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });


  const COUNTER_INIT = 999999999999999;
  const [liveAt, setLiveAt] = useState<number>(COUNTER_INIT);
  const [showCountdownTimer, setShowCountdownTimer] = useState<boolean>(false);
  const [days, hours, minutes, seconds] = useCountdown(liveAt, onZero); // 1674846000

  function onZero() {
    if (showCountdownTimer) {
      setShowCountdownTimer(false);

      if (songData) {
        const d: SongDataType = songData
        d.live = true
        setSongData(d)
      }
     
    }
  }

  useEffect(() => {
    setShowFCABanner(true)
    return () => {
      setShowFCABanner(false)
    };
  }, []);

  useEffect(() => {
    if (showBioDataModal)
      (window as any).gtag('event', 'song_profile_bio_data', {});
  }, [showBioDataModal]);

  useEffect(() => {
    if (showInfoBenefitsModal)
      (window as any).gtag('event', 'song_profile_info_benefits', {});
  }, [showInfoBenefitsModal]);

  useEffect(() => {
    if (isPlaying) (window as any).gtag('event', 'song_profile_song_play', {});
  }, [isPlaying]);

  useEffect(() => {

    const perks: PurchasePerksType[] = []
    restService.songPerks(song || '').then((res) => {
      
      res.forEach((element: any) => {
        
        perks.push({
          type: element.type,
          title: element.title,
          orderNumber: element.order_number,
          description: element.description.replaceAll('|', ''),
          imageLogo: element.image_logo
        })

      });

      setSongPerks(perks)
    }).catch((error) => {
      error
    })

  }, [])

  useEffect(() => {
    if (songProfile && songProfile.songBySlug && songPerks) {
      const { songBySlug: data } = songProfile;

      const is_live = checkLive(data.releaseAt);

      // 0 -> false, 1 -> true

      const registered_interest = data.artistProfileByArtistId.userByUserId.artistIntrestsByArtist.edges.length !== 0;
      const registered_waitlist = data.artistProfileByArtistId.userByUserId.waitlistsByArtist.edges.length !== 0;

      if (registered_waitlist || registered_interest) {
        setHasRegisteredInterest(true);  
      }

      const totalBits = data.audioLengthMs;
      const bitCount = JSON.parse(data.bitCount)
      let soldBits = bitCount.sold_bits;
      let remainingBits = bitCount.remaining_bits;
      let soldBitsInverted = bitCount.sold_bits_inverted;
      let actualBitPrice = data.actualBitPrice;

      if (data.slug === 'drop') {
        soldBits = totalBits;
        remainingBits = 0;
        soldBitsInverted = 0;
        actualBitPrice = 0.1;
      }

      const soldPercent = getSoldPercent(
        data.audioLengthMs,
        data.editionsCount,
        soldBitsInverted,
      );

      const songData = {
        id: data.id,
        title: data.title,
        artistName: data.artistName,
        artistSlug: data.artistProfileByArtistId.slug,
        artistIntro: data.artistProfileByArtistId.intro,
        artistId: data.artistProfileByArtistId.userId,
        description: data.description,
        songSlug: data.slug,
        audioLengthMs: data.audioLengthMs,
        actualBitPrice: actualBitPrice,
        editions: data.editionsCount,
        remainingBits: remainingBits,
        soldBits: soldBits,
        soldBitsInverted: soldBitsInverted,
        soldPercent: soldPercent,
        artwork: data.fileByCoverId.url,
        songId: data.id,
        songUrl: data.fileByPreviewFile?.url,
        songType: data.songType,
        musicLinks: {
          spotify: data.spotify,
          applemusic: data.apple,
        },
        releaseAt: data.releaseAt,
        status: data.status,
        background: data.fileByBackgroundId?.url,
        live: is_live.now_epoch > is_live.date_epoch,
        perks: songPerks,
        explicitContent: data.explicitContent,
        song_type: data.songType,
      };

      setLiveAt(is_live.date_epoch);
      setSongData(songData);

      createViewTracking({
        variables: {
          type: 'SONG',
          viewedItem: data.id,
          userId: null,
        },
      });
    }
  }, [songProfile, songPerks]);

  useEffect(() => {
    if (liveAt !== COUNTER_INIT && liveAt > new Date().getTime()) {
      setTimeout(() => {
        setShowCountdownTimer(true);
      }, 1000);
    }
  }, [liveAt]);

  useEffect(() => {
    if (songData) {
      setBreadcrumbs([
        { value: 'Home', label: 'Home' },
        { value: songData.artistSlug || '', label: songData.artistName },
        { value: songData.songSlug, label: songData.title },
      ]);
    }
    return () => {
      setBreadcrumbs([]);
    };
  }, [songData]);

  useEffect(() => {
    setMainFooterHidden(true);
    updateHeaderTransparence(true);
    updateLogoShown(true);
    setUseTraparentHeaderSwitch(true);

    return () => {
      updateHeaderTransparence(false);
      updateLogoShown(false);
      setMainFooterHidden(false);
      setUseTraparentHeaderSwitch(true);
    };
  }, []);

  const getSongTime = () => {
    if (songData) {
      return (
        Math.trunc(songData.audioLengthMs / 1000 / 60) +
        'm ' +
        (Math.trunc(songData.audioLengthMs / 1000) % 60) +
        's'
      );
    }
  };

  function formatDate(date: string) {
    const str_date = date;

    const year = parseInt(str_date.substring(0, 4));
    const month = parseInt(str_date.substring(5, 7)) - 1;
    const day = parseInt(str_date.substring(8, 10));

    const hour = parseInt(str_date.substring(11, 13));
    const minute = parseInt(str_date.substring(14, 16));
    const second = parseInt(str_date.substring(17, 19));

    const d = new Date(
      Date.UTC(year, month, day, hour, minute, second),
    ).toUTCString();

    const values = d.split(' ');

    const time = values[4].split(':');
    const formatted_time = time[0] + ':' + time[1];

    return (
      values[1] +
      ' ' +
      values[2] +
      ' ' +
      values[3] +
      ' | ' +
      formatted_time +
      ' ' +
      values[5] +
      '+1/BST'
    );
  }

  const unauthClickHandler = () => {
    if (!isAuthenticated) {
      setReturnUrl(location.pathname);
      setShowUnauthModal(true);
    } else {
      if (songData) {
        navigate('/' + songData.artistSlug + '/' + songData.songSlug + '/bits');
      }
    }
  };

  const playerClickHandler = () => {
    if (songData?.musicLinks.applemusic || songData?.musicLinks.spotify) {
      setShowListenNowModal(true);
    } else {
      setIsPlaying((prev) => !prev);
    }
  };

  const handleCostClick = () => {
    if (isCostView) setIsCostView(false);
    else setIsCostView(true);
  };

  const USCost = (
    <>
      {songData && (
        <>
          US${songData.actualBitPrice}/bit {/*<LightText>(view USDC)</LightText>*/}
        </>
      )}
    </>
  );

  const DAICost = (
    <>
      {songData && (
        <>
          {songData.actualBitPrice > 1
            ? Math.round(songData.actualBitPrice * MOCK_SONG_DATA.DAI)
            : (songData.actualBitPrice * MOCK_SONG_DATA.DAI)
                .toFixed(2)
                .toString()}
          USDC/bit <LightText>(view USD)</LightText>
        </>
      )}
    </>
  );

  const background = useCallback(() => {
    if (songData) {
      if (songData.background) {
        return songData.background;
      } else {
        return Banner;
      }
    } else {
      return Banner;
    }
  }, [songData]);

  return (
    <>
      {songData && songPerks && (isAuthenticated && songData) || (!isAuthenticated && songData) &&
      (!songData.songType ||
      songData.songType === 'RELEASED' ||
      songData.songType === 'SOLD_OUT' ||
      songData.songType === 'COMING_SOON' ||
      songData.songType === 'SALE_WITHDRAWN' ||
      songData.songType === 'SALE_CANCELLED') &&
      songData.artistSlug === user ? (
        <>
          <PageContainer
            pageTitle={
              songData.title + ' : ' + songData.artistName + ' | SongBits'
            }
            pageDescription={
              songData.title +
              ' - ' +
              songData.artistName +
              ' on SongBits. A platform where you can buy shared ownership in songs directly from your favourite artists and earn royalties together with them for life.'
            }
            bgBanner={`${background()}`}
            reduceFooter="0"
            reduceFooterMobile="0">
            <MainContainer fcaBanner={showFCABanner && (userLocation === 'GB' || userLocation === 'NI' || userLocation === 'UK')}>
              <RowContainer>
                <SongBannerContainer>
                  <ReactHowler
                    src={songData.songUrl}
                    playing={isPlaying}
                    onEnd={() => setIsPlaying(false)}
                  />
                  <PlayButton onClick={playerClickHandler}>
                    {isPlaying ? (
                      <PlayImage src={PauseImg} alt="Pause" />
                    ) : (
                      <PlayImage src={PlayImg} alt="Play" />
                    )}
                  </PlayButton>
                  <SongImage
                    playing={isPlaying}
                    src={songData.artwork}></SongImage>
                </SongBannerContainer>

                <ContentContainer>
                  {songData.explicitContent && (
                    <ExplicitContainer>
                      <ExplicitLyrics />
                    </ExplicitContainer>
                  )}
                  {songData.title.length > 16 ? (
                    <>
                      <StrapLineTypographyLarge
                        text={songData.title}
                        fontColor={theme.colors.white}
                        fontSize="fz100"
                      />
                    </>
                  ) : (
                    <>
                      <StrapLineTypography
                        text={songData.title}
                        fontColor={theme.colors.white}
                        fontSize="fz100"
                      />
                    </>
                  )}

                  {!isMobile() && (
                    <>
                      <SongMainInfo>{songData.artistName}</SongMainInfo>
                      {showCountdownTimer &&
                        songData.songType !== 'COMING_SOON' && (
                          <CountdownContainer>
                            <Typography
                              text={`${days > 0 ? days : 0}d:${
                                hours > 0 ? hours : 0
                              }h:${minutes > 0 ? minutes : 0}m:${
                                seconds < 10 ? 0 : ''
                              }${seconds > 0 ? seconds : 0}s`}
                              fontWeight="bold"
                              fontSize="fz24"
                            />
                            <Typography
                              text={`(${formatDate(songData.releaseAt)})`}
                              fontWeight="light"
                              fontSize="fz16"
                            />
                          </CountdownContainer>
                        )}
                      <SongExtraInfo>
                        <SongInfo>
                          {getSongTime()}{' '}
                          <LightText>
                            ({songData.audioLengthMs.toLocaleString('en-US')}{' '}
                            bits)
                          </LightText>{' '}
                          {songData.songType &&
                          songData.songType === 'COMING_SOON' ? (
                            <></>
                          ) : (
                            <>
                              <Divider>|</Divider>{' '}
                              <Cost onClick={handleCostClick}>
                                {isCostView ? USCost : DAICost}
                              </Cost>{' '}
                              {Number(songData.soldPercent) > 75 ? (
                                <>
                                  <Divider>|</Divider> {songData.soldPercent}%
                                  Sold
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </SongInfo>
                      </SongExtraInfo>
                      {songData.perks.length > 0 ? (
                        <>
                          <SongProfileIconsSection
                            perks={songData.perks}
                            onClick={() => {
                              setShowInfoBenefitsModal(true);
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}

                  <ButtonsContainer>
                    {isMobile() ? (
                      <>
                        {songData.perks.length > 0 ? (
                          <>
                            <SongProfileIconsSection
                              perks={songData.perks}
                              onClick={() => {
                                setShowInfoBenefitsModal(true);
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <Spacer height={10} />
                          </>
                        )}
                        <MobileButtonsContainer>
                          <Button
                            id="bio-data-button"
                            disabled={false}
                            backgroundColor="#000000"
                            textColor="#FFFFFF"
                            border={'#FFD600'}
                            onClick={() => setShowBioDataModal(true)}>
                            bio and data
                          </Button>

                          {songData.perks.length > 0 ? (
                            <>
                              <Button
                                id="benefits-button"
                                disabled={false}
                                backgroundColor="#000000"
                                textColor="#FFFFFF"
                                border={'#FFD600'}
                                onClick={() => setShowInfoBenefitsModal(true)}>
                                benefits
                              </Button>
                            </>
                          ) : (
                            <></>
                          )}
                        </MobileButtonsContainer>
                      </>
                    ) : (
                      <>
                        <Button
                          id="bio-data-button"
                          disabled={false}
                          backgroundColor="#000000"
                          textColor="#FFFFFF"
                          border={'#FFD600'}
                          onClick={() => setShowBioDataModal(true)}>
                          bio and data
                        </Button>

                        {songData.perks.length > 0 ? (
                          <>
                            <Button
                              id="benefits-button"
                              disabled={false}
                              backgroundColor="#000000"
                              textColor="#FFFFFF"
                              border={'#FFD600'}
                              onClick={() => setShowInfoBenefitsModal(true)}>
                              benefits
                            </Button>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}

                    {!songData.live ? (
                      <>
                        {isAuthenticated ? (
                          <>
                            {hasRegisteredInterest ? (
                              <>
                                <Button
                                  disabled={true}
                                  border={'#FFD600'}
                                  textColor={'#000000'}
                                  backgroundColor={'#FFD600'}
                                  opacity={0.5}
                                  onClick={unauthClickHandler}>
                                  on the list
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button
                                  id="keep-informed-button"
                                  border={'#FFD600'}
                                  textColor={'#000000'}
                                  backgroundColor={'#FFD600'}
                                  onClick={() => {
                                    setShowRegisterInterestModal(true);
                                  }}>
                                  keep informed
                                </Button>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <Button
                              id="keep-informed-button"
                              border={'#FFD600'}
                              textColor={'#000000'}
                              backgroundColor={'#FFD600'}
                              onClick={() => {
                                unauthClickHandler();
                              }}>
                              keep informed
                            </Button>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {songData.songType === 'SALE_WITHDRAWN' ? (
                          <>
                            <Button
                              id="song-profile-public-buy-button"
                              className="song-profile-public-buy-button"
                              disabled={true}
                              border={
                                '#000000'
                              }
                              textColor={
                                '#FFFFFF'
                              }
                              backgroundColor={
                                '#000000'
                              }
                              onClick={() => {}}>
                              {'sale ended'}
                            </Button>
                          </>
                        ) : (
                          <>
                            {!isArtist && (
                            <Button
                              id="song-profile-public-buy-button"
                              className="song-profile-public-buy-button"
                              disabled={songData.soldBitsInverted === 0}
                              border={
                                songData.soldBitsInverted > 0
                                  ? '#FFD600'
                                  : '#000000'
                              }
                              textColor={
                                songData.soldBitsInverted > 0
                                  ? '#000000'
                                  : '#FFFFFF'
                              }
                              backgroundColor={
                                songData.soldBitsInverted > 0
                                  ? '#FFD600'
                                  : '#000000'
                              }
                              onClick={unauthClickHandler}>
                              {songData.soldBitsInverted > 0
                                ? 'buy now'
                                : 'sold out'}
                            </Button>
                            )
                          }
                          </>
                        )}
                      </>
                    )}
                  </ButtonsContainer>

                  {isMobile() && (
                    <>
                      <SongMainInfo>{songData.artistName}</SongMainInfo>
                      {showCountdownTimer &&
                        songData.songType !== 'COMING_SOON' && (
                          <CountdownContainer>
                            <Typography
                              text={`${days > 0 ? days : 0}d:${
                                hours > 0 ? hours : 0
                              }h:${minutes > 0 ? minutes : 0}m:${
                                seconds < 10 ? 0 : ''
                              }${seconds > 0 ? seconds : 0}s`}
                              fontWeight="bold"
                              fontSize="fz18"
                            />
                            <Typography
                              text={`(${formatDate(songData.releaseAt)})`}
                              fontWeight="light"
                              fontSize="fz12"
                            />
                          </CountdownContainer>
                        )}
                      <SongExtraInfo>
                        <SongInfo>
                          {getSongTime()}{' '}
                          <LightText>
                            ({songData.audioLengthMs.toLocaleString('en-US')}{' '}
                            bits)
                          </LightText>
                          {songData.songType &&
                          songData.songType === 'COMING_SOON' ? (
                            <></>
                          ) : (
                            <>
                              {' '}
                              <Divider>|</Divider>{' '}
                              <Cost onClick={handleCostClick}>
                                {isCostView ? USCost : DAICost}
                              </Cost>{' '}
                              {Number(songData.soldPercent) > 75 ? (
                                <>
                                  <Divider>|</Divider> {songData.soldPercent}%
                                  Sold
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </SongInfo>
                      </SongExtraInfo>
                    </>
                  )}
                </ContentContainer>
              </RowContainer>
            </MainContainer>
          </PageContainer>

          <SBModal
            className={'sign-in'}
            isOpen={showUnauthModal}
            height="310px"
            top="35%"
            content={
              <SignInModal
                onClose={() => {
                  setShowUnauthModal(false);
                }}
                artistId={songData.artistId}
              />
            }
          />

          <SBModal
            className={'listen-now'}
            isOpen={showListenNowModal}
            height="400px"
            width="435px"
            top="30%"
            content={
              <ListenNowModal
                musicLinks={songData.musicLinks}
                songURL={songData.songUrl}
                onClose={() => {
                  setShowListenNowModal(false);
                }}
              />
            }
          />

          <SBModal
            className={'benefits'}
            isOpen={showInfoBenefitsModal}
            height="575px"
            width="450px"
            withProceedingText={false}
            mobileWidth={`${viewport.width - 34}px`}
            mobileHeight="100vh"
            mobileTop={'3%'}
            content={
              <InfoBenefitsModal
                onClose={() => setShowInfoBenefitsModal(false)}
                perks={songData.perks}
              />
            }
          />

          <SBModal
            className={'bio'}
            isOpen={showBioDataModal}
            height="700px"
            width="450px"
            mobileWidth={`${viewport.width - 34}px`}
            mobileHeight="100vh"
            mobileTop={'3%'}
            //top={viewport.width > xs ? '20%' : '10%'}
            overflow="scroll"
            content={
              <BioDataModal
                onClose={() => setShowBioDataModal(false)}
                intro={songData.artistIntro}
                description={songData.description}
              />
            }
          />

          <SBModal
            className={'register-interest'}
            isOpen={showRegisterInterestModal}
            height="300px"
            mobileHeight="100%"
            width="410px"
            top="28%"
            content={
              <RegisterInterestModal
                artistId={songData.artistId}
                onRegisterInterest={() => {
                  setHasRegisteredInterest(true);
                }}
                onClose={() => {
                  setShowRegisterInterestModal(false);
                }}
              />
            }
          />
        </>
      ) : (
        <>
          {(songData && songData.artistSlug !== user) ||
          (!songData && songProfile) ? (
            <ErrorPage />
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default WithRouteAnimation(SongProfilePublic);
